import React, { useEffect, useState } from 'react';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { NUMBER_INPUT, TIN_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useTinUtils } from 'utils/tin/tin';
import { useItemsUtils } from 'utils/items/items';

import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput, RadioButtonInput, DropdownInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import {
	setTinType,
	setGermanTin,
	setFatcaCountry,
	setTaxIdentificationNumber,
	setTaxIdentificationNumberReason
} from 'store/Tin/actions';

const Tin = () => {
	const { getCurrentFlow, isStaticSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage } = useValidationUtils();
	const { isTinValid } = useTinUtils();
	const { getTinTypeItems, getNationalityItems, getUsTinReasonDropdownItems } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeTinType = useSelector((state => state.tin.tinType));
	const storeGermanTin = useSelector((state => state.tin.germanTin));
	const storeFatcaCountry = useSelector((state => state.tin.fatcaCountry));
	const storeTaxIdentificationNumber = useSelector((state => state.tin.taxIdentificationNumber));
	const storeTaxIdentificationNumberReason = useSelector((state => state.tin.taxIdentificationNumberReason));

	const [prevTinType, setPrevTinType] = useState(storeTinType);

	const [tinTypeError, setTinTypeError] = useState(null);
	const [germanTinError, setGermanTinError] = useState(null);
	const [fatcaCountryError, setFatcaCountryError] = useState(null);
	const [taxIdentificationNumberError, setTaxIdentificationNumberError] = useState(null);
	const [taxIdentificationNumberReasonError, setTaxIdentificationNumberReasonError] = useState(null);

	const tinTypeItems = getTinTypeItems();
	const nationalityItems = getNationalityItems();
	const taxIdentificationNumberReasonItems = getUsTinReasonDropdownItems();

	const showUsTin = isStaticSubType(null, [C.FRONTEND.DEPOT]);

	useEffect(() => {
		if (storeTinType !== prevTinType) {
			if (storeTinType === tinTypeItems[1].value) {
				dispatch(setFatcaCountry('US'));
			} else {
				dispatch(setFatcaCountry(null));
			}
			dispatch(setTaxIdentificationNumber(null));
			dispatch(setTaxIdentificationNumberReason(null));
		}
		setPrevTinType(storeTinType);
	}, [storeTinType]);

	const clearErrors = () => {
		setTinTypeError(null);
		setGermanTinError(null);
		setFatcaCountryError(null);
		setTaxIdentificationNumberError(null);
		setTaxIdentificationNumberReasonError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isGermanTinValid = true;

		if (isEmpty(storeTinType)) {
			setTinTypeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (showUsTin && storeTinType === tinTypeItems[1].value) {
			if (isEmpty(storeTaxIdentificationNumber)) {
				setTaxIdentificationNumberError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			if (isEmpty(storeTaxIdentificationNumberReason)) {
				setTaxIdentificationNumberReasonError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
		} else if (storeTinType === tinTypeItems[2].value) {
			if (isEmpty(storeFatcaCountry)) {
				setFatcaCountryError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			if (isEmpty(storeTaxIdentificationNumber)) {
				setTaxIdentificationNumberError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
		}
		if (storeGermanTin && !isTinValid(storeGermanTin)) {
			setGermanTinError(m('validation.error.tin.invalid', 'fields'));
			isGermanTinValid = false;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		if (!hasEmptyFields && !isGermanTinValid) {
			addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
		}

		const isValid = !hasEmptyFields && isGermanTinValid;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if (
			currentFlow === C.FRONTEND.DEPOT &&
			storeTinType === tinTypeItems[1].value
		) {
			goToPage(currentFlow + C.ROUTES.NO_US);
		} else {
			if (currentFlow === C.FRONTEND.DEPOT) {
				goToPage(currentFlow + C.ROUTES.STOCK_PROFICIENCY);
			} else if (currentFlow === C.FRONTEND.GMK || currentFlow === C.FRONTEND.SPARBRIEF) {
				goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
			} else {
				goToPage(currentFlow + C.ROUTES.DISPO);
			}
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setTinType('Nein'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.taxNumber.title', 'global')}</Title>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={7}
				onPrevButton={() => {
					if (currentFlow === C.FRONTEND.GMK || currentFlow === C.FRONTEND.SPARBRIEF) {
						goToPage(currentFlow + C.ROUTES.BANK_ACCOUNT);
					} else {
						goToPage(currentFlow + C.ROUTES.OCCUPATION);
					}
				}}
			>
				<RadioButtonInput
					value={storeTinType !== null ? storeTinType : ''}
					setValue={(value) => { dispatch(setTinType(value)); }}
					items={tinTypeItems}
					hasError={!!tinTypeError}
					message={tinTypeError}
					tabindex={1}
					testId="tin-type"
				/>

				{storeTinType === tinTypeItems[2].value && (
					<DropdownInput
						value={storeFatcaCountry}
						setValue={(value) => { dispatch(setFatcaCountry(value)); }}
						items={nationalityItems}
						hasError={!!fatcaCountryError}
						message={fatcaCountryError}
						label={m('taxInformation.label', 'fields')}
						tabindex={2}
						testId="fatca-country"
					/>
				)}

				{((showUsTin && storeTinType === tinTypeItems[1].value) || storeTinType === tinTypeItems[2].value) && (
					<TextInput
						value={storeTaxIdentificationNumber}
						setValue={(value) => { dispatch(setTaxIdentificationNumber(value)); }}
						label={showUsTin && storeTinType === tinTypeItems[1].value ? m('usTin.label', 'fields') : m('foreignTin.label', 'fields')}
						hasError={!!taxIdentificationNumberError}
						message={taxIdentificationNumberError}
						maxLength={showUsTin && storeTinType === tinTypeItems[1].value ? 9 : 128}
						regex={showUsTin && storeTinType === tinTypeItems[1].value ? NUMBER_INPUT : TIN_INPUT}
						tabindex={3}
						testId="tin-international"
					/>
				)}

				{showUsTin && storeTinType === tinTypeItems[1].value && (
					<DropdownInput
						value={storeTaxIdentificationNumberReason}
						setValue={(value) => { dispatch(setTaxIdentificationNumberReason(value)); }}
						items={taxIdentificationNumberReasonItems}
						hasError={!!taxIdentificationNumberReasonError}
						message={taxIdentificationNumberReasonError}
						label={m('usTin.reason.label', 'fields')}
						tabindex={4}
						testId="tin-reason"
					/>
				)}

				<div className={(showUsTin && storeTinType === tinTypeItems[1].value) || storeTinType === tinTypeItems[2].value ? 'mt--15' : null}>
					<Title size="s" mb>
						<strong>
							{
								currentFlow === C.FRONTEND.GIRO
									? m('taxInformation.tinTitle', currentFlow)
									: m('pages.taxNumber.tinTitle', 'global')
							}
						</strong>
					</Title>
					<Text size="l" mb>{m('pages.taxNumber.tinSubtitle', 'global', null, true)}</Text>
				</div>

				<TextInput
					value={storeGermanTin}
					setValue={(value) => { dispatch(setGermanTin(value)); }}
					label={m('germanTin.label', 'fields')}
					hasError={!!germanTinError}
					message={germanTinError}
					maxLength={11}
					regex={NUMBER_INPUT}
					tabindex={5}
					testId="tin"
				/>
			</Form>
		</>
	);
};
export default Tin;
