import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useRegistrationApi } from 'api/registration';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import SvgEmailCheck from 'components/Atoms/SVG/Icons/SvgEmailCheck';

import styles from './EmailConfirmed.module.scss';

const EmailConfirmed = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { patchEmailConfirmation } = useRegistrationApi();

	const currentFlow = getCurrentFlow();

	const { accessToken } = useParams();

	useEffect(() => {
		if (accessToken) {
			patchEmailConfirmation(
				accessToken,
				() => {},
				() => {
					continueInvalid();
					goToPage(currentFlow + C.ROUTES.EMAIL_NOT_CONFIRMED);
				}
			);
		} else {
			continueInvalid();
			goToPage(currentFlow + C.ROUTES.EMAIL_NOT_CONFIRMED);
		}
	}, []);

	const onSubmit = async () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.MOBILE_NUMBER);
	};

	return (
		<>
			<Row flexColumnMobile>
				<Col noShrink noGrow>
					<div className={styles.icon}>
						<SvgEmailCheck />
					</div>
				</Col>
				<Col grow>
					<Title mb>{m('pages.emailConfirmed.title', 'global')}</Title>
					<Text size="l">{m('pages.emailConfirmed.subtitle', 'global')}</Text>
				</Col>
			</Row>
			<Form
				onSubmit={onSubmit}
				submitTabindex={1}
			/>
		</>
	);
};
export default EmailConfirmed;
