import React, { useEffect, useMemo } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useApplicationApi } from 'api/application';

import { Col, Row } from 'components/Atoms/Grid';
import { Link, Text, Title } from 'components/Atoms/Text';
import Box from 'components/Molecules/Box/Box';
import Form from 'components/Molecules/Form/Form';
import Divider from 'components/Atoms/Divider/Divider';
import SvgIllustrationSecondaryCard from 'components/Atoms/SVG/Illustrations/SvgIllustrationSecondaryCard';
import SvgAlert from 'components/Atoms/SVG/Icons/SvgAlert';
import SvgPaymentEuro from 'components/Atoms/SVG/Icons/SvgPaymentEuro';
import SvgShareLink from 'components/Atoms/SVG/Icons/SvgShareLink';

import bestCardBasicSrc from 'assets/img/best_card_basic.png';
import bestCardSmartSrc from 'assets/img/best_card_smart.png';
import bestCardExtraSrc from 'assets/img/best_card_extra.png';
import bestCardPremiumSrc from 'assets/img/best_card_premium.png';
import sanVisaSrc from 'assets/img/san_visa.png';

import { useSelector } from 'react-redux';

import styles from './CreditCardSuccess.module.scss';

const CreditCardSuccess = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { checkIsFromNav } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { getAccountSwitchUpdate } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const storeNewAccountPan = useSelector((state => state.newAccount.pan));
	const storeNewAccountIban = useSelector((state => state.newAccount.iban));
	const storeRequestFromCrossSelling = useSelector((state => state.app.requestFromCrossSelling));

	useEffect(() => {
		checkIsFromNav();
	}, []);

	const getCardGraphic = useMemo(() => {
		switch (currentFlow) {
			case C.FRONTEND.BEST_CARD_BASIC:
				return (
					<img
						src={bestCardBasicSrc}
						alt="Santander BestCard Basic"
					/>
				);
			case C.FRONTEND.BEST_CARD_SMART:
				return (
					<img
						src={bestCardSmartSrc}
						alt="Santander BestCard Smart"
					/>
				);
			case C.FRONTEND.BEST_CARD_EXTRA:
				return (
					<img
						src={bestCardExtraSrc}
						alt="Santander BestCard Extra"
					/>
				);
			case C.FRONTEND.BEST_CARD_PREMIUM:
				return (
					<img
						src={bestCardPremiumSrc}
						alt="Santander BestCard Premium"
					/>
				);
			case C.FRONTEND.VISACARD:
				return <SvgIllustrationSecondaryCard />;
			default:
				return <SvgIllustrationSecondaryCard />;
		}
	}, [currentFlow]);

	const onSubmitAccountSwitch = async () => {
		await getAccountSwitchUpdate(
			() => {
				continueValid();
			},
			() => {
				continueInvalid();
			}
		);
	};

	return (
		<>
			<Title mb>
				{m('creditCardSuccess.title', currentFlow)}
			</Title>
			<Row>
				<Col xs={12} sm={4}>
					<div className={styles.card}>
						{getCardGraphic}
					</div>
				</Col>
				<Col xs={12} sm={8}>
					{storeNewAccountPan ? (
						<>
							<Text><strong>Ihre Kreditkartennummer</strong></Text>
							<Text>{storeNewAccountPan}</Text>
						</>
					) : (
						<Text>{m('creditCardSuccess.noPan', currentFlow)}</Text>
					)}
				</Col>
			</Row>
			{
				process.env.REACT_APP_ENABLE_BEST_CARD_GIRO_CROSS_SELLING === 'true'
				&& isCreditCardSubType(null, [C.FRONTEND.VISACARD])
				&& storeRequestFromCrossSelling
				&& (
				<>
					<Divider noLine noMarginBottom />
					<Row>
						<Col xs={12} sm={4}>
							<div className={styles.card}>
								{<img src={sanVisaSrc} alt="Santander Visa" />}
							</div>
						</Col>
						<Col xs={12} sm={8}>
							{storeNewAccountIban ? (
								<>
									<Text><strong>IBAN (am besten gleich notieren)</strong></Text>
									<Text>{storeNewAccountIban}</Text>
								</>
							) : (
								<Text>{m('creditCardSuccess.noIban', currentFlow)}</Text>
							)}
						</Col>
					</Row>
				</>
			)}
			{
				process.env.REACT_APP_ENABLE_BEST_CARD_GIRO_CROSS_SELLING === 'true'
				&& isCreditCardSubType(null, [C.FRONTEND.VISACARD])
				&& storeRequestFromCrossSelling
				&& storeNewAccountIban
				&& (
				<div className="mt--15">
					<Title size="s" mb>
						{m('accountSuccess.accountChange.title', C.FRONTEND.GIRO)}
					</Title>
					<Text size="s" mb>
						{m(
							'accountSuccess.accountChange.description',
							C.FRONTEND.GIRO,
							null,
							true
						)}
					</Text>
					<Form
						onSubmit={onSubmitAccountSwitch}
						clearErrors={() => {}}
						submitTabindex={1}
						submitText={m('accountSuccess.accountChange.buttonText', C.FRONTEND.GIRO)}
					/>
				</div>
			)}
			{currentFlow === C.FRONTEND.VISACARD && (
				<>
					<Divider noLine />
					<Box
						size="s"
						border
						borderRadius
						isRow
						icon={<SvgAlert color="#ec0000" />}
					>
						<Text>{m('creditCardSuccess.hint', currentFlow)}</Text>
					</Box>
				</>
			)}
			{currentFlow === C.FRONTEND.BEST_CARD_BASIC && (
				<>
					<Divider noLine />
					<Box
						shadow
						borderRadius
					>
						<Text mb><SvgPaymentEuro color="#ec0000" /><strong>{m('creditCardSuccess.hint.title', currentFlow)}</strong></Text>
						<Text mb>{m('creditCardSuccess.hint.description', currentFlow)}</Text>
						<Text>
							<Link
								href="https://santander.aklamio.de?utm_source=santander_application&utm_medium=referral&utm_campaign=aklamio"
								target="_blank"
							>
								{m('creditCardSuccess.hint.button', currentFlow)}
								<SvgShareLink />
							</Link>
						</Text>
					</Box>
				</>
			)}
		</>
	);
};
export default CreditCardSuccess;
