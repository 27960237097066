module.exports = {
	title: 'BestCard Smart',
	progressBar: {
		step1: 'Persönliche Angaben',
		step2: 'Absicherung',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zu Ihrer BestCard Smart."
		}
	},
	birthdate: {
		subtitle: 'Für die Beantragung der Kreditkarte müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie die BestCard Smart digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	bankAccount: {
		subtitle:
			'Geben Sie hier die IBAN Ihres Girokontos an, von dem die Zahlungen mit der Kreditkarte abgebucht werden sollen.'
	},
	insurance: {
		title: 'Absicherung',
		ppi: {
			title: 'Absicherung',
			subtitle: 'Die Santander RSV Plus dient zur Absicherungen, auch im Ernstfall. Wir empfehlen den Abschluss der Santander RSV Plus soweit Sie eine Absicherung der Rückzahlung wünschen.',
			body: {
				title: 'Ratenschutzversicherung',
				death: {
					title: 'Ratenschutzversicherung: Todesfall',
					label: 'Absicherung gegen Tod, Unfalltod, Arbeitsunfähigkeit und Arbeitslosigkeit',
					labelSenior: 'Absicherung gegen Tod und Unfalltod'
				},
				noPpi: {
					label: 'Ich will keine Absicherung.'
				},
				hint1: '1) Eine Absicherung bei unverschuldeter Arbeitslosigkeit oder Arbeitsunfähigkeit durch Krankheit oder Unfall wird beitragsfrei durch den Versicherer CNP Insurance Europe DAC bereitgestellt. Es gelten die jeweils gültigen Versicherungsbedingungen des Versicherers.'
			},
			noPopup: {
				title: 'Keine Ratenschutzversicherung',
				description:
					'Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung der Kreditkarte, bietet Ihnen und Ihrer Familie jedoch im Falle des Todes (und ggf. der Arbeitsunfähigkeit sowie der Arbeitslosigkeit) ein hohes Maß an Sicherheit vor finanziellen Belastungen.<br /><br />Sollten Sie keine weitere Absicherung haben und auch keine Absicherung wünschen, tragen Sie auch bei Eintreten unvorhersehbarer Ereignisse weiterhin alle Kosten selber. Bei weiteren Fragen können Sie sich gerne an unsere Filialen wenden oder uns telefonisch unter <a class="link primary" href="tel:+4921619060599">02161 - 90 60 599</a> erreichen.'
			}
		},
		protect: {
			title: 'Einkommensausfall absichern',
			subtitle: 'Mit Santander Protect können Sie Ihr monatliches Einkommen gegen eine Vielzahl von Risiken absichern.',
			body: {
				title: 'Deckungs- und Absicherungslaufzeit wählen',
				subtitle: 'Aufgrund Ihrer zuvor eingegebenen Gehaltsangaben haben wir hiermit Ihre Einkommenslücke berechnet, bei einer max. monatlichen Absicherung von 800 €. Die Deckungs- und Absicherungslaufzeit können Sie individuell anpassen.',
				runtime: {
					label: 'Laufzeit'
				},
				monthlyProtect: {
					label: 'Monatliche Absicherung'
				},
				selectTitle: 'Protect Tarif wählen',
				table: {
					row1: {
						title: 'Kurzzeitpflege, Elternzeit',
						popup: {
							headline1: 'Kurzzeitpflege',
							content1: '<p>Sie sorgen für den Menschen, der Ihnen wichtig ist. Wir sorgen für Ihr Einkommen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Kurzzeitpflege ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Die Pflege direkter und naher Verwandter ist mitversichert.</li></ul>',
							headline2: 'Elternzeit',
							content2: '<p>Nehmen Sie sich Zeit für Ihre Familie – ohne Sorgen um Ihr Einkommen</p><ul><li>Beispiel: Das Basiselterngeld beträgt in der Regel 65-67% des Nettoeinkommens. Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Elternzeit ca. 660 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Adoptionen sind mitversichert.</li></ul>'
						}
					},
					row2: {
						title: 'Kurzarbeit, Arbeitslosigkeit, schwere Erkrankungen',
						popup: {
							headline1: 'Kurzarbeit',
							content1: '<p>Auch in schwierigen Zeiten bleibt Ihr Einkommen stabil</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der konjunkturellen Kurzarbeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 50% der vereinbarten monatlichen Leistung.</li><li>Auch bei befristeten Arbeitsverträgen.</li></ul>',
							headline2: 'Arbeitslosigkeit',
							content2: '<p>Fokussieren Sie sich auf Ihren nächsten Job – und nicht auf Ihre finanziellen Sorgen</p><ul><li>Planbares Einkommen trotz Arbeitslosigkeit: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Arbeitslosigkeit ca. 800 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Arbeitnehmer und Angestellte im öffentlichen Dienst sind mitversichert.</li></ul>',
							headline3: 'Schwere Erkrankungen',
							content3: '<p>Konzentrieren Sie sich auf Ihre Genesung – ohne Geldsorgen</p><ul><li>Gilt nur für Beamte, Selbstständige und Rentner.</li><li>Versichert sind Krankheiten wie beispielsweise Krebs, Herzinfarkt, Schlaganfall, etc.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li></ul>'
						}
					},
					row3: {
						title: 'Arbeitsunfähigkeit',
						popup: {
							headline1: 'Arbeitsunfähigkeit',
							content1: '<p>Konzentrieren Sie sich auf Ihre Genesung - ohne Geldsorgen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Arbeitsunfähigkeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 50% der vereinbarten monatlichen Leistung.</li><li>Ohne Gesundheitsfragen.</li><li>Psychische Erkrankungen sind mitversichert.</li></ul>'
						}
					},
					row4: {
						title: 'Unfalltod',
						popup: {
							headline1: 'Unfalltod',
							content1: '<p>Sorgen Sie vor – für Ihre Liebsten</p><ul><li>Hinterbliebene absichern mit einer einmaligen Versicherungsleistung ohne Wartezeit.</li><li>Die Versicherungsleistung beträgt das 50-fache des gewählten Leistungsbetrages (maximal 150.000 €).</li></ul>'
						}
					},
					standard: {
						title: 'Protect<br />Standard'
					},
					premium: {
						title: 'Protect<br />Premium',
						higlightLabel: 'Top Seller'
					},
					basic: {
						title: 'Protect<br />Basis'
					},
					noProtect: 'Ich will meinen Einkommensausfall nicht absichern'
				}
			}
		}
	},
	paymentProtectionInsurance: {
		title: 'Absicherung', // 'Wünschen Sie noch einen Schutz für Ihre Kreditkarte?',
		subtitle:
			'Damit Sie im Fall der Fälle nicht auch noch finanzielle Sorgen haben, sorgen Sie besser jetzt richtig vor mit der Ratenschutzversicherung.',
		completeProtection: {
			label: 'Mein Schutz',
			check1: 'Arbeitslosigkeit',
			check2: 'Arbeitsunfähigkeit',
			check3: 'Todesfall',
			popUp: {
				headline: 'Komplettschutz',
				subhead:
					'Finanzielle Absicherung im Todesfall, bei Unfalltod, Arbeitsunfähigkeit und Arbeitslosigkeit',
				content:
					'<strong>Todesfall:</strong> Übernahme des ausstehenden Sollsaldo Ihrer Kreditkarte<br/><br/><strong>Unfalltod:</strong> Zusätzliche Zahlung des ausstehenden Sollsaldo Ihrer Kreditkarte an die Hinterbliebenen<br/><br/><strong>Arbeitsunfähigkeit:</strong> Übernahme der versicherten Raten für Ihren Sollsaldo, im Falle der vorrübergehenden Arbeitsunfähigkeit durch Unfall oder Krankheit<br/><br/><strong>Arbeitslosigkeit:</strong> Übernahme der versicherten Raten für Ihren Sollsaldo im Falle der unverschuldeten Arbeitslosigkeit<br/><br/>Der Versicherungsschutz beginnt nach einer Wartezeit von 3 Monaten und ohne Gesundheitsprüfung, ab Vertragsunterschrift und kann nach Abschluss mit einer Kündigungsfrist von einem Monat zum Schluss eines jeden Monats gekündigt werden. Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung der Kreditkarte.'
			}
		},
		standardProtection: {
			label: 'Standardschutz',
			check1: 'Todesfall',
			check2: 'Unfalltod',
			check3: 'Arbeitsunfähigkeit',
			check4: 'Arbeitslosigkeit',
			popUp: {
				headline: 'Standardschutz',
				subhead:
					'Finanzielle Absicherung im Todesfall, bei Unfalltod und Arbeitsunfähigkeit',
				content:
					'<strong>Todesfall:</strong> Übernahme der gemäß Tilgungsplan noch offenen Summe<br/><br/><strong>Unfalltod:</strong> Zusätzliche Zahlung der gemäß Tilgungsplan noch offenen Summe an die Hinterbliebenen<br/><br/><strong>Arbeitsunfähigkeit:</strong> Übernahme der versicherten Raten im Falle der vorrübergehenden Arbeitsunfähigkeit durch Unfall oder Krankheit<br/><br/>Der Versicherungsschutz besteht ohne Gesundheitsprüfung und beginnt nach einer Wartezeit von 3 Monaten ab Darlehensauszahlung (Ausnahme Unfalltod). Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung des Darlehens und kann auch nach Abschluss unabhängig vom Darlehensvertrag mit einer Kündigungsfrist von einem Monat zum Schluss eines jeden Monats gekündigt werden.'
			}
		},
		noRsv: {
			label: 'Ich brauche keinen Schutz',
			popUp: {
				headline: 'Keine Ratenschutzversicherung',
				content:
					'Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung der Kreditkarte, bietet Ihnen und Ihrer Familie jedoch im Falle des Todes (und ggf. der Arbeitsunfähigkeit sowie der Arbeitslosigkeit) ein hohes Maß an Sicherheit vor finanziellen Belastungen.<br/><br/>Sollten Sie keine weitere Absicherung haben und auch keine Absicherung wünschen, tragen Sie auch bei Eintreten unvorhersehbarer Ereignisse weiterhin alle Kosten selber. Bei weiteren Fragen können Sie sich gerne an unsere Filialen wenden oder uns telefonisch unter <a class="link primary" href="tel:+4921619060123">02161 - 90 60 123</a> erreichen.'
			}
		}
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Kreditkarten'
		},
		submit: 'Jetzt Kreditkarte beantragen'
	},
	bcoResponseGreen: {
		title: 'Sie haben die BestCard Smart erfolgreich beantragt.',
		nextSteps: {
			title: 'Ihre letzten Schritte bis zum Abschluss',
			step1: 'Identifikation',
			step2: 'Vertrag unterschreiben',
			step3: 'BestCard erhalten'
		},
		submit: 'Jetzt fortführen',
		bestGiro: {
			banner: 'Jetzt kostenloses BestGiro Konto in einem Schritt mit beantragen',
			label: 'BestGiro Konto jetzt mit eröffnen',
			list: {
				item1: 'Kontowechselservice nutzen und 75€ Prämie sichern',
				item1Tooltip: {
					title: 'Teilnahmebedingungen:',
					item1: 'Gilt nur für Personen, die in den letzten 12 Monaten kein Girokonto bei Santander hatten (Neukunden).',
					item2: 'Abschluss eines BestGiro, 1|2|3 Giro oder BestGiro für Studenten.',
					item3: 'Sie stimmen zu, dass mindestens 4 Zahlungspartner durch unseren Kontowechselservice informiert und angeschrieben werden (Hierzu zählen Lastschriften für z.B. Strom, Handy, Internet, Miete oder Gutschriften wie Gehalt oder Rente).',
					item4: 'Das eröffnete Girokonto darf nicht in ein Pfändungsschutzkonto umgewandelt werden.',
					item5: 'Auszahlung erfolgt 3 Monate nach erfolgreicher Durchführung des Kontowechselservice zum Monatsende auf Ihr Santander Girokonto.',
					item6: 'Der Willkommensbonus ist steuerfrei, wenn Ihre Einkünfte aus sonstigen Leistungen (inkl. Willkommensbonus) weniger als 256 € im Jahr betragen. Darüber hinausgehende Einkünfte sind von Ihnen an das zuständige Finanzamt zu übermitteln.',
					linkText: 'Echtzeitüberweisung'
				},
				item2: 'Keine Kontoführungsgebühren und kein Mindestgeldeingang'
			},
			submit: {
				withBestGiro: 'Weiter mit BestGiro Konto',
				withoutBestGiro: 'Weiter ohne BestGiro Konto'
			}
		}
	},
	bcoResponseGreenLimit: {
		title: 'Gute Neuigkeiten!<br/>Nach unserer Prüfung können wir Ihnen die BestCard Smart mit einem Verfügungsrahmen von {limit}€ anbieten.',
		hint: 'Ihr gewünschter Verfügungsrahmen in Höhe von {limit}€ kann derzeit leider nicht eingeräumt werden.',
		submit: 'Antrag abschließen'
	},
	giroSuccess: {
		titleWithLimit:
			'Super, wir können Ihnen auch das BestGiro mit Limit anbieten',
		titleWithoutLimit:
			'Geschafft! Ihr BestGiro Konto ist beantragt.',
		subtitle:
			'Bitte laden Sie sich den Kontoeröffnungsantrag für Ihre persönlichen Unterlagen herunter.',
		submitButton: 'Jetzt identifizieren',
		downloadLink: 'BestGiro Konto Vertrag (BestGiro.pdf)',
		error:
			'Der gewünschte Dispositionskredit kann derzeit nicht eingeräumt werden.',
		next: {
			title: 'Nur noch zwei Schritte bis zur Aktivierung:',
			list: {
				item1: 'Identifizieren Sie sich für Ihre BestCard und Ihr BestGiro Konto',
				item2: 'Unterschreiben Sie für Ihre BestCard'
			}
		}
	},
	giroFailed: {
		title: 'Wir können Ihnen das BestGiro Konto leider nicht mit anbieten.',
		description: 'Fahren Sie nun final mit den letzten Schritten der BestCard fort.',
		submit: 'Jetzt identifizieren'
	},
	identification: {
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen und digital unterschreiben.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		}
	},
	download: {
		contract: {
			application: 'Kreditkartenantrag herunterladen',
			pdftitle: 'Kreditkarten-Vertrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kreditkarteneröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	activateCreditCardAccount: {
		title: 'Sie haben sich erfolgreich identifiziert',
		button: 'Jetzt Kreditkarte aktivieren'
	},
	creditCardSuccess: {
		title: 'Geschafft! Die Karte und Ihre Login Daten für das Online-Banking sind in wenigen Tagen unterwegs zu Ihnen.',
		noPan: 'Die Kreditkartennummer erhalten Sie in wenigen Tagen.',
		noIban: 'Ihre IBAN erhalten Sie in wenigen Tagen.'
	},
	exitPopup: {
		title: 'BestCard Smart Antrag',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Smart und sicher shoppen mit Ihrer Kreditkarte.',
		continueButton: 'Fortfahren'
	}
};
