import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';

import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';
import CreditCalculator from 'components/Organisms/CreditCalculator/CreditCalculator';

import { useDispatch, useSelector } from 'react-redux';
import { updateSidebarExtensionActive, setSidebarActive, setSidebarAnimation, updateSidebarRuntimeAnimation } from 'store/App/actions';

import styles from './RightSideBar.module.scss';

const RightSideBar = (props) => {
	const { getReturn } = useKeycodeUtils();

	const dispatch = useDispatch();
	const storeSidebarActive = useSelector((state => state.app.sidebarActive));
	const storeSidebarRuntimeAnimation = useSelector((state => state.app.sidebarRuntimeAnimation));

	useEffect(() => {
		const body = document.getElementsByTagName('body')[0];
		if (!storeSidebarActive) {
			body.classList.remove('no-scroll');
			dispatch(updateSidebarExtensionActive(false));
		} else {
			window.scrollTo(0, 0);
			body.classList.add('no-scroll');
		}
	}, [storeSidebarActive]);

	const onClose = () => {
		dispatch(setSidebarActive(false));
		dispatch(setSidebarAnimation(false));
		dispatch(updateSidebarRuntimeAnimation(false));
	};

	const handleKeyDown = (event) => {
		if (getReturn(event)) {
			onClose(event);
		}
	};

	return (
		<>
			<div
				className={
					styles.backgroundBlurOverlay +
					(storeSidebarActive ? ' ' + styles.active : '')
				}
				onClick={onClose}
			/>

			<div
				data-testid='right-side-bar'
				className={
					styles.rightSideBar +
					(storeSidebarActive ? ' ' + styles.isOpen : '')
				}
			>
				<div className={styles.wrapper}>
					<div
						className={styles.closeButton}
						onClick={() => {
							dispatch(setSidebarActive(false));
						}}
						onKeyDown={handleKeyDown}
						tabIndex={98}
					>
						<SvgClose />
					</div>

					<div
						className={
							storeSidebarRuntimeAnimation
								? ' ' + styles.runtimeOverlay
								: ''
						}
					/>

					<div className={props.creditCalculatorBlocked ? styles.sideBarBlock : null} />

					<CreditCalculator />
				</div>
			</div>
		</>
	);
};

RightSideBar.propTypes = {
	creditCalculatorBlocked: PropTypes.bool
};

export default RightSideBar;
